.audio-animation{
    position: relative;
    top: 0;
  }
  
  #audio-visual {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #audio-visual .audio-wire {
    height: 50%;
    margin: 0 3px;
    border-radius: 2px;
    padding: 0 2px;
    background-color: black;
    animation: animationUpDwon 0.5s infinite alternate;
  }
  #audio-visual .audio-wire-1 {
    animation-delay: 50ms;
  }
  #audio-visual .audio-wire-2 {
    animation-delay: 150ms;
  }
  #audio-visual .audio-wire-3 {
    animation-delay: 300ms;
  }
  #audio-visual .audio-wire-4 {
    animation-delay: 450ms;
  }
  #audio-visual .audio-wire-5 {
    animation-delay: 600ms;
  }
  #audio-visual .audio-wire-6 {
    animation-delay: 450ms;
  }
  #audio-visual .audio-wire-7 {
    animation-delay: 300ms;
  }
  #audio-visual .audio-wire-8 {
    animation-delay: 150ms;
  }
  #audio-visual .audio-wire-9 {
    animation-delay: 50ms;
  }
  .container h1 {
    font-size: 2rem;
    font-weight: 800;
  }
  .container p {
    font-size: 0.85rem;
    color: rgb(189, 189, 189);
  }
  @keyframes animationUpDwon {
    to {
      height: 90%;
    }
    from {
      height: 50%;
    }
  }

.text-and-button-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: absolute;
    bottom: 10px;
    z-index: 800;
    left: 50%;
    transform: translateX(-50%);
  }

  .faq-section-block{
    width: 800px;
    background-color: rgba(255, 255, 255, 0.3);
    font-size: 1.875rem;
    /* padding: 30px; */
    display: flex;
    flex-direction: column;
    /* gap: 30px; */
  }

  .faq-section-block h1:nth-child(1){
    font-weight: bold;
    font-size: 3rem;
  }

  .button button{
    width: 150px;
    height: 150px;
    text-align: center;
    line-height: 150px;
    border-radius: 50%;
  }

